<template>
	<div class="schedule-modal-table">
		<ModalTemplate v-model="isOpen" 
		name="schedule-modal-table"
		queryName="m_smt"
		:closer="closeHandler">
			<div class="modal-container">
				<div class="modal-header">
					<div class="header-back" @click="closeModal">
						<span class="iconify"  data-width="24" data-height="24" data-icon="uil:arrow-left"></span>
					</div>
				</div>
				<div class="modal-carousel">
					<ScheduleTable
						:daysCount="daysCount"
						:selectedDate="selectedDate"
						:slotCollection="slotCollection"
						:workTime="workTime"
						:isEditable="false"
						:calendar="calendar"
						@on-select-date="(value) => date = value"
					/>
				</div>
			</div>
		</ModalTemplate>
	</div>
</template>

<script>
import { enShortMonths } from '../../scripts/months';
import { cssVw } from '../../scripts/cssUnits';
import SlotCollection from '../../scripts/slotCollection';
import CalendarWorkTime from '../../scripts/calendarWorkTime';
import ModalTemplate from '../modals/ModalTemplate.vue';
import BCarouselListMod from '../BCarouselListMod.vue';
import ModalProvider from '../../scripts/modalProvider';
import ScheduleTable from './ScheduleTable.vue';

export default {
	name: 'ScheduleModalTable',
	components: {
		ScheduleTable,
		ModalTemplate,
		BCarouselListMod,
	},
	model: {
		prop: 'selectedDate',
		event: 'update:selectedDate',
	},
	props: {
		isModalOpen: {
			type: Boolean,
			required: true,
		},
		selectedDate: {
			type: Date,
			required: true,
		},
		slotCollection: {
			type: SlotCollection,
			required: true,
		},
		daysCount: {
			type: Number,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		months: {
			type: Array,
			default: () => [],
		},
		closeHandler: {
			type: Function,
			required: true,
		},
	},
	computed: {
		isOpen: {
			get(){
				return this.isModalOpen
			},
			set(newValue){
				if (newValue == false)
					this.closeModal()
				this.currentDateIndex = 1
			}
		},
		date: {
			get(){
				return this.selectedDate
			},
			set(newValue){
				this.$emit('update:selectedDate', newValue)
			}
		},
		selectedDateTitle(){
			let title = `${this.date.getDate()} ${enShortMonths[this.date.getMonth()]}` 
		
			let selectedDateYear = this.date.getFullYear()
			let currentYear = new Date().getFullYear()

			if (selectedDateYear != currentYear)
				return `${title} ${selectedDateYear}`
			
			return title
		},
		calendar(){
			return this.$store.getters.currentCalendar()
		},
	},
	data() {
		return {
			editTableCellHeight: 54,
			currentDateIndex: 1,
			isSwipe: false,
			dates: [],
		};
	},
	watch: {
		selectedDate(newValue) {
			if (this.isSwipe)
				return
			this.setupDates(newValue)
		}
	},
	created() {
		this.setupDates(this.date)
	},
	methods: {
		closeModal(){
			ModalProvider.back()
		},	
		inputDateHandler(newValue){
			this.isSwipe = true
			setTimeout(() => this.isSwipe = false, 100)
			if (newValue > 1 && newValue == this.dates.length - 1) {
				this.pushDateRight()

				this.currentDateIndex = this.dates.length - 2 
				let newDate = new Date(this.date)
				newDate.setDate(newDate.getDate() + daysCount)
				this.date = newDate
				return
			}
			if (newValue < 1 ) {
				setTimeout(() => {
					this.pushDateLeft()

					let scheduleTable = document.getElementById('schedule-modal-table');
					let scheduleTableSlides = scheduleTable.getElementsByClassName('carousel-slides')[0];

					scheduleTableSlides.style.transform = `translateX(-${100*cssVw}px)`
					scheduleTableSlides.style.transition = 'none';
					this.currentDateIndex = 1
					setTimeout(() => {
						scheduleTableSlides.style.transition = null;
					}, 0);
					this.date = this.dates[1]
				}, 50);
				return
			}
			this.date = this.dates[newValue]
		},
		setupDates(newValue){
			let dates = []
			
			let prevDate = new Date(newValue)
			prevDate.setDate(prevDate.getDate() - 1)
			dates.push(prevDate)

			dates.push(newValue)

			let nextDate = new Date(newValue)
			nextDate.setDate(nextDate.getDate() + 1)
			dates.push(nextDate)
			this.dates = dates
		},
		pushDateRight() {
			let date = new Date(this.dates[this.dates.length - 1]);
			date.setDate(date.getDate() + 1);
			this.dates.push(date);

			date = new Date(date)
			date.setMonth(date.getMonth() + 1)
		},
		pushDateLeft() {
			let dates = [...this.dates]
			let date = new Date(this.dates[0]);
			date.setDate(date.getDate() - 1);
			dates.unshift(date);
			this.dates = dates;

			date = new Date(date)
			date.setMonth(date.getMonth() - 1)
		},
	}
};
</script>

<style>
.carousel-arrow .icon.has-icons-right {
    right: calc( 1.5rem - 24px);
	background: transparent;
}
.carousel-arrow .icon.has-icons-left {
    left: calc( 1.5rem - 24px);
	background: transparent;
}
</style>

<style scoped>
.modal-container{
	background: white;
    position: absolute;
	border-radius: 8px;
	top: 42px;
	left: 20px;
	right: 20px;
	bottom: 66px;

	display: flex;
	flex-direction: column;
	height: calc(100% - 42px - 66px);
}

.modal-carousel{
	width: 100%;
	height: calc(100% - 32px - 24px);
	flex: 1;
}

.modal-header{
	position: absolute;
	top: 5px;
	left: 5px;

	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 16px;
	font-size: 20px;
	font-weight: 500;
	z-index: 2;
}

.header-back{
	height: 24px;
}

</style>
