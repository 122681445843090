<template>
	<div class="calendar-schedule-page" v-if="calendar && !calendar.isRestricted">
		 
		<!-- Date calendar -->

		<Calendar v-model="daysCount" 
			:months="months"
			:selectedDate="selectedDate"
			:workTime="workTime"
			@on-swipe="inputMonthHandler"
			@on-select-date="selectDateHandler"
		/>

		<!-- Schedule -->
		<ScheduleTable
			:daysCount="daysCount"
			:selectedDate="selectedDate"
			:workTime="workTime"
			:isEditable="isEditable"
			:calendar="calendar"
			@on-select-date="selectDateHandler"
		/>

		<SlotConfirmationModal
			:initSlot="openedConfirmationSlot"
		/>
	</div>
</template>

<script>
import { makeMonthByDate } from '../scripts/week';
import YYYYMMDD, { convertTZ, getCurrentWeekV2, isEqualDate, nextDate, smartDateSelect, weekStart } from '../scripts/date';
import ScheduleTable from '../components/schedule/ScheduleTable.vue';
import Calendar from '../components/schedule/calendar/Calendar.vue';
import SlotCollection from '../scripts/slotCollection';
import { globalBus } from '../GlobalEventBus.js';
import SlotConfirmationModal from '../components/modals/slot-confirmation-modal/SlotConfirmationModal.vue';

import CalendarWorkTime from '../scripts/calendarWorkTime';
import CalendarMonth from '../scripts/calendarMonth';

export default {
    name: 'CalendarSchedule',
	components: {
		ScheduleTable,
		Calendar,
		SlotConfirmationModal,
	},
    data() {
        return {
            selectedDate: null,
            months: [],
			monthTakeWaitList: [],
			scrollTop: 0,
			daysCount: null,
			currentYear: null,
			currentMonth: null,

			openedConfirmationSlot: null,
        };
    },
	computed: {
		calendarUid(){
			return this.$route.params.calendarUid
		},

		calendar(){
			if (!this.calendarUid)
				return undefined
			return this.$store.getters.getCalendarByUid(this.calendarUid)
		},

		calendarTimezone(){
			if (!this.calendar)
				return undefined
			const timezoneId = this.calendar.timezoneId
			return this.$store.getters.getTimezoneByID(timezoneId)
		},

		browserTimezone(){
            return this.$store.getters.browserTimezone
		},

		slotCollection(){
			return this.$store.getters.calendarSlotCollection
		},

		workTime(){
			return !this.calendar ?
				new CalendarWorkTime(new Date(), new Date()) : this.calendar.workTime
		},

		isEditable(){
			return !this.calendar ?
				false : this.calendar.isEditable
		},
	},
	beforeRouteLeave (to, from, next) {

		globalBus.$off(`calendar-schedule-${this.calendarUid}`)

		let isSlotPage = to.name == 'slot-edit' || to.name == 'slot-attend'

		if (from.name == 'calendar-schedule') {
			this.saveCurrentScrollPosition()
		}
		this.saveCurrentSettings(to.name)
		next()
	},
    created() {
        // Setup initial data about months and orders
		this.setupQueryVariables()
		this.months = this.calcMonths(nextDate(this.selectedDate, this.daysCount - 1))
		let calendarUid = this.$route.params.calendarUid
		globalBus.$on(`calendar-schedule-${calendarUid}`, 'add-slot', (slots) => {
			this.slotCollection.addSlots(slots)
		})
		const currentMonthIndex = this.months
		.findIndex(month => {
			const dateRangeEnd = nextDate(this.selectedDate, this.daysCount - 1)
			return month.includes(dateRangeEnd)
		})
		this.setupSlots({currentMonthIndex})
	},
	beforeDestroy() {
		let calendarUid = this.$route.params.calendarUid
		globalBus.$off(`calendar-schedule-${calendarUid}`)
	},

	watch: {
		selectedDate(newDate, oldDate) {
			if (!oldDate || newDate == oldDate)
				return

			let query = {
				dt: YYYYMMDD(newDate),
			}

			this.updateUrl(query)
		},
		daysCount(newDays, oldDays) {
			if (!oldDays || newDays == oldDays)
				return

			let query = {
				dr: newDays,
				dt: YYYYMMDD(this.selectedDate),
			}

			if (newDays == 7)
				this.selectedDate = weekStart(this.selectedDate)
			else if (newDays > oldDays) {
				this.selectedDate = smartDateSelect(this.selectedDate, newDays)
			}

			this.updateUrl(query)
		},
		'$route.params.calendarUid': {
			handler: function(newCalendarUid, oldCalendarUid) {
				if (newCalendarUid == oldCalendarUid)
					return

				this.setupQueryVariables()

				let calendarUid = newCalendarUid
				if (this.months.length == 0) {
					this.months = this.calcMonths(nextDate(this.selectedDate, this.daysCount - 1))
				}
				
				if (this.slotCollection && this.slotCollection.calendarUid != calendarUid){
					const currentMonthIndex = this.months
					.findIndex(month => {
						const dateRangeEnd = nextDate(this.selectedDate, this.daysCount - 1)
						return month.includes(dateRangeEnd)
					})
					this.setupSlots({currentMonthIndex})	
				}
			},
			deep: true,
			immediate: true
		},
	},

    methods: {
		saveCurrentScrollPosition(){
			let table = document.getElementById('schedule-table')
			let activeCarouselSlide = table.querySelector('.carousel-slide.is-active')

			if (!activeCarouselSlide)
				return
			let activeCalendarTable = activeCarouselSlide.querySelector('.calendar-v2')
			if (!activeCalendarTable)
				return
			let scroll = activeCalendarTable.scrollTop
			sessionStorage.setItem(`schedule-table-${this.calendarUid}-scroll`, scroll)
		},

		saveCurrentSettings(newRouteName){
			let prefix 
			
			if (newRouteName == 'slot-edit') {
				prefix = 'edit'
			} else if (newRouteName == 'slot-attend') {
				prefix = 'attend'
			} else if (newRouteName == 'calendar-settings-attenders-menu') {
				prefix = 'attenders'
			} else if (newRouteName == 'calendar-settings-menu') {
				prefix = 'settings'
			} else if (newRouteName == 'calendar-settings-host-menu') {
				prefix = 'hosts'
			} else if (newRouteName == 'account-settings') {
				prefix = 'account'
			} else {
				return
			}
			
			const currentPageData = {
				name: 'calendar-schedule',
				params: this.$route.params,
				query: this.$route.query
			}
			localStorage.setItem(`originPage-${prefix}`, JSON.stringify(currentPageData))
		},

		inputMonthHandler(newValue){
			
			if (this.months[newValue + 1] == undefined
			|| this.months[newValue - 1] == undefined) {
				const monthDate = this.months[newValue].firstDate
				this.months = this.calcMonths(monthDate)
			}

			this.setupSlots({currentMonthIndex: newValue})
		},

		dateRangeOutOfWeek(date, daysCount, weekStart){
			if (daysCount == 1 || daysCount == 7 && date.getDay() == weekStart)
				return false
			if (daysCount == 7 && date.getDay() != weekStart)
				return true
			const dateRangeEnd = new Date(date)
			dateRangeEnd.setDate(dateRangeEnd.getDate() + daysCount - 1)

			const dateRangeStartDay = date.getDay() || 7
			const dateRangeEndDay = dateRangeEnd.getDay() || 7

			return dateRangeEndDay < dateRangeStartDay
		},

		setupQueryVariables(){

			// Setup selected date
			let date = this.$route.query.dt ?
				new Date(this.$route.query.dt) :
			 	new Date()

			// Setup days count
			const dateRange = Number(this.$route.query.dr)
			this.daysCount = dateRange ? dateRange : 1

			// Setup selected date
			const isDateRangeOutOfWeek = this.dateRangeOutOfWeek(date, this.daysCount, 1)
			this.selectedDate = isDateRangeOutOfWeek ?
				smartDateSelect(date, this.daysCount) : date

			this.currentYear = this.selectedDate.getFullYear()
			this.currentMonth = this.selectedDate.getMonth()
		},

		setupSlots({currentMonthIndex, start, end}){
			if (currentMonthIndex != undefined) {
				const currentMonth = this.months[currentMonthIndex]
				start = currentMonth.from
				end = currentMonth.to
			}

			if (!this.browserTimezone || !this.calendarTimezone)
				return setTimeout(() => this.setupSlots({currentMonthIndex, start, end}), 100)

			const browserTz = this.browserTimezone
			const calendarTz = this.calendarTimezone
			const from = convertTZ(start, this.calendarTimezone, this.browserTimezone)
			const to = convertTZ(end, this.calendarTimezone, this.browserTimezone)

			const paramsIsEqual = this.slotCollection.loadingParamsIsEqual(from, to, browserTz, calendarTz) 
			if (this.slotCollection.isLoading || paramsIsEqual)
				return
			this.slotCollection.updateSlots(from, to, browserTz, calendarTz)
				.then(this.setupConfirmationSlot)
		},

		setupConfirmationSlot(){
			if (this.openedConfirmationSlot)
				return
			const confirmationSlotId = this.$route.query['m_scm']
			if (!confirmationSlotId || Number(confirmationSlotId) == NaN)
				return
			const slotId = Number(confirmationSlotId)
			const slot = this.slotCollection.getById(slotId)
			if (!slot)
				return
			this.openedConfirmationSlot = slot
		},

		isInCalendar(date){
			return !!this.months.find(month => {
				return month.includes(date)
			})
		},	

        calcMonths(date, rangeBefore=6, rangeAfter=6) {
			const startDate = new Date(date)
			startDate.setDate(1)
			
			let counter = 0
			while (counter < rangeBefore) {
				startDate.setMonth(startDate.getMonth() - 1, 1)
				counter++
			}

			const months = []
			while (months.length < rangeBefore + rangeAfter + 1) {
				months.push(new CalendarMonth(startDate))
				startDate.setMonth(startDate.getMonth() + 1, 1)
				counter++;
			}
			return months
        },
		selectDateHandler(newDate, isUpdateCalendar=true){

			if (isUpdateCalendar && !this.isInCalendar(newDate)) {
				const currentMonthIndex = this.months
				.findIndex(month => {
					const dateRangeEnd = nextDate(this.selectedDate, this.daysCount - 1)
					return month.includes(dateRangeEnd)
				})
				this.setupSlots({currentMonthIndex})
			}

			this.selectedDate = newDate
		},

		updateUrl(query){
			if (this.$route.query.dt != undefined && query.dt == undefined)
				query.dt = this.$route.query.tb

			if (this.$route.query.tb != undefined && query.tb == undefined)
				query.tb = this.$route.query.tb

			if (this.$route.query.st != undefined && query.st == undefined)
				query.st = this.$route.query.st

			if (this.$route.query.dr != undefined && query.dr == undefined)
				query.dr = this.$route.query.dr

			this.$router.replace({
				name : 'calendar-schedule',
				params: {
					calendarUid: this.calendarUid
				},
				query: query,
			}).catch(() => {})
		}
	},
};
</script>

<style>
.carousel-slide{
	height: 100%;
}
.carousel-slides{
	height: 100%;
	/* overflow-y: hidden; */
}
</style>

<style scoped>

.calendar-schedule-page{
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.calendar-schedule-page > *:first-child {
	flex: 0 0 auto;
}

.calendar-schedule-page > *:not(:first-child) {
  	flex-grow: 1;
}

#schedule-calendar{
	box-shadow: none;
}

#schedule-table{
	height: 100%;
}
.header-title{
	font-size: 20px;
	font-weight: 600;
	position: fixed;
	z-index: 3;
	padding: 10px;
	width: calc(100% - 54px);
	height: 54px;
	display: flex;
	align-items: center;
}

.carousel-list{
	background: white;
	width: 100%;
}

</style>