<template>
	<div class="past-time-backdrop" v-if="backdropIsActive" v-bind:style="{
		display: backdropIsActive  ? 'block' : 'none',
		filter: boxShadow,
	}">

		<div class="past-time-backdrop__shape" v-bind:style="{
			'clip-path': clipPath,
		}"></div>

		<!-- <div class="past-time-backdrop__item" 
		v-for="item in tableHeader.week"
		:key="`past-time-backdrop-${item.date.toLocaleDateString()}`"
		v-bind:style="{
			display: dateIsPast(item.date) ? 'block' : 'none',
			height: `${calcPastTimeHeight(item.date)}px`,
			backgroundColor: color,
			width: `${columnWidth + 1}px`,
		}"></div> -->
	</div>
</template>

<script>
import CalendarHeader from '../../scripts/calendarHeader';
import CalendarWorkTime from '../../scripts/calendarWorkTime';
import { dateIsPast, isToday } from '../../scripts/date';

export default {
	name: 'PastTimeBackdrop',
	props: {
		tableHeader : {
			type: CalendarHeader,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		container: {
			type: HTMLElement,
		},
		color: {
			type: String,
			default: "#c2c2c2",
		},
		columnCount: {
			type: Number,
			default: 7,
		}
	},
	computed: {
		backdropIsActive(){
			return this.tableHeader && dateIsPast(this.tableHeader.week[0].date)
		},
		containerElement(){
			if (this.container)
				return this.container
			if (this.$el)
				return this.$el.parentElement
			return null
		},
		boxShadow(){
			return `drop-shadow(0 0 6px ${this.color})`
		},

	},

	watch: {
		container(){
			this.clipPath = this.calcClipPath()
		},
		columnCount(){
			this.clipPath = this.calcClipPath()
		},
		tableHeader(){
			this.clipPath = this.calcClipPath()
		},
		workTime(){
			this.clipPath = this.calcClipPath()
		},
	},

	data() {
		return {
			clipPath: null,
		}
	},

	mounted(){
		this.clipPath = this.calcClipPath()
        setInterval(() => {this.clipPath = this.calcClipPath()}, 60000)
	},

	methods: {
		dateIsPast: dateIsPast,
		calcContainerHeight(){
			if(!this.containerElement)
				return 0
			return this.containerElement.scrollHeight
		},
		calcPastTimeHeight(date){
			if (!date || !dateIsPast(date) || !this.containerElement)
				return 0
			const redLine = this.containerElement.querySelector('.red__line')
			if (!redLine)
				return 0
			const redLineTop = redLine.style["top"].replace('px', '')
			if (Number(redLineTop) == NaN)
				return 0
			return (Number(redLineTop))
		},
		calcColumnWidth(){
			this.columnCount
			const container = this.container
			if (!container)
				return 0
			const columnElem = container.querySelector('.calendar-column')
			if (!columnElem)
				return 0
			const columnRect = columnElem.getBoundingClientRect()
			return columnRect.width
		},
		calcPastDateCount(){
			return this.tableHeader.week.filter(date => dateIsPast(date.date)).length
		},
		calcClipPath(){
			const week = this.tableHeader.week
			const isFirstDatePast = dateIsPast(week[0].date)
			if (this.columnCount != 1) {
				const lastDate = week[week.length - 1].date
				const isLastDatePast = dateIsPast(lastDate)
				if (isLastDatePast && !isToday(lastDate))
					return `polygon(0 0, 100% 0, 100% 100%, 0 100%)`
			}

			if (isFirstDatePast && this.columnCount == 1 && !isToday(this.tableHeader.week[0].date))
				return `polygon(0 0, 100% 0, 100% 100%, 0 100%)`
			else if (!isFirstDatePast)	
				return `inset(0 0 0 0)`
				
			const columnWidth = this.calcColumnWidth()
			const pastDateCount = this.calcPastDateCount()
			const backdropWidth = columnWidth * pastDateCount

			const today = new Date()
			today.setHours(0, 0, 0, 0)
			const todayColumnHeight = this.calcPastTimeHeight(today)

			return `polygon(0 0, 
				${backdropWidth}px 0, 
				${backdropWidth}px ${todayColumnHeight}px,
				${backdropWidth - columnWidth}px ${todayColumnHeight}px,
				${backdropWidth - columnWidth}px 100%,
				0 100%)`		
		},

	},
};
</script>

<style scoped>

.past-time-backdrop {
	position: absolute;
	top: 10px;
	bottom: 0;
	left: 30px;
	right: 16px;
	opacity: 0.2;
	border-radius: 2px;
}

.past-time-backdrop__shape{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	background: lightgray;
	border-radius: 2px;
}

/* .past-time-backdrop::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px rgba(205, 17, 17, 1); 
    z-index: -1;
    border-radius: 10px; 
} */


</style>