<template>
	<div id="schedule-table">
		<BCarouselListMod without-buttons
			id="schedule-table-carousel"
			v-model="currentDateIndex"
			:data="dates" :items-to-show="1"
			:hasDrag="daysCount == 1"
			@input="inputDateHandler"
			@on-drag-start="syncTableTime"
			@on-drag-end="makeCalendarScrollable">
			<template #item="date" >
				<ScheduleTableFrame
					v-if="workTime"
					:date="date.list"
					:daysCount="daysCount"
					:isEditable="isEditable"
					:editTableCellHeight="editTableCellHeight"
					:workTime="workTime.projection(date.list)"
					:disableHeader="disableHeader"
					:calendar="calendar"
				/>
			</template>
		</BCarouselListMod>
		
		<ScheduleTableDelete
			:show="slotMoveEvent && pageActivity == 'create-slot'"
			:isDeletePreparation="isDeletePreparation"
		/>

		<ScheduleCreateSlot v-if="slotMoveEvent 
				&& isEditable 
				&& (!pageActivity || pageActivity == 'create-slot')" 
			:moveEvent="slotMoveEvent"
			:workTime="workTime"
			:selectedDate="selectedDate"
			:isGridView="isEditable"
			:columnCount="daysCount"
			:isDeletePreparation="isDeletePreparation"
			:calendar="calendar"
			@on-drag-start="setPageActivity('create-slot')"
			@on-drag-move="checkIntersectionWithBasket"
			@on-drag-end="submitSlot"
		/>
	</div>
</template>

<script>
import { cssVw } from '../../scripts/cssUnits';
import { dateIsPast, dateRange, isEqualDate, nextDate } from '../../scripts/date';
import ScheduleTableFrame from './ScheduleTableFrame.vue';
import ScheduleCreateSlot from './ScheduleCreateSlot.vue';
import BCarouselListMod from '../BCarouselListMod.vue';
import CalendarWorkTime from '../../scripts/calendarWorkTime';
import SlotCollection from '../../scripts/slotCollection';
import { scrollByCondition } from '../../scripts/scroll';
import ScheduleTableDelete from './ScheduleTableDelete.vue';
import Calendar from '../../scripts/calendar';
import Slot from '../../scripts/slot';
import eventBroker from '../calendar-v2/eventBus';
export default {
	name: "ScheduleTable",
	components: {
		ScheduleTableFrame,
		ScheduleCreateSlot,
		ScheduleTableDelete,
		BCarouselListMod,
	},
	emits: [
		'on-select-date',
	],
	props: {
		selectedDate: {
			type: Date,
			required: true,
		},
		calendar: {
			type: Calendar,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
		disableHeader: {
			type: Boolean,
			default: false,
		},
		daysCount: {
			type: Number,
			required: true,
		},
	},
	data(){
		return {
			currentDateIndex: 0,
			dates: [],
			isSwipeActive: false,
			currentTimeIsSet: false,
			editTableCellHeight: 54,
			isDeletePreparation: false,
		}
	},
	watch: {


		selectedDate(newValue, oldValue) {
			if (!oldValue)
				return
			if (this.isSwipeActive)
				return 
			if (this.daysCount == 1){
				this.setupDates(newValue)
				this.currentDateIndex = 1

			} else {
				this.dates = [newValue]
				this.currentDateIndex = 0
			}
		},
		workTime(newValue){
			if (!newValue || this.currentTimeIsSet)
				return
			if (this.workTime && this.slotCollection.isLoaded){
				setTimeout(this.setupCalendarScroll, 10)
			}
		}, 
		daysCount(value, oldValue){

			if (value == 1 && oldValue != 1 || value != 1 && oldValue == 1){
				let activeTable = this.activeEditableTable()
				if (activeTable) {
					sessionStorage.setItem(`schedule-table-${this.calendarUid}-scroll`, activeTable.scrollTop)
				}
			}

			if (value == 1)
				this.currentDateIndex = 1
			else
				this.currentDateIndex = 0

			this.withoutTransition(() => {
				if (value == 1) {
					this.setupDates(this.selectedDate)
				} else {
					this.dates = [this.selectedDate]
				}
				const activeTable = this.activeEditableTable()
				if (activeTable)
					this.$nextTick().then(this.setScrollWithoutTransition)
			})
		},
	},

	computed: {
		slotMoveEvent(){
			return this.$store.getters.tableCreateEvent
		},
		calendarUid(){
			return this.$route.params.calendarUid
		},
		pageActivity(){
			return this.$store.getters.pageActivity
		},
		slotCollection(){
			return this.$store.getters.calendarSlotCollection
		}

	},
	created() {
		this.setupDates(this.selectedDate)
	},

	mounted() {
		if (this.daysCount == 1)
			this.currentDateIndex = 1
		else
			this.currentDateIndex = 0
		this.withoutTransition()
		.then(this.setupScroll)
	},

	methods: {
		setupScroll(){
			if (!this.calendar.isEditable) {
				this.currentTimeIsSet = true
				return
			}

			const savedOldScroll = sessionStorage.getItem(`schedule-table-${this.calendarUid}-scroll`)
			if (savedOldScroll) {
				this.setScrollWithoutTransition()
			} else {
				const action = () => {
					if (!this.slotCollection.isLoaded)
						return setTimeout(action, 100)
					const tableElem = this.activeEditableTable()
					if (!tableElem) {
						this.currentTimeIsSet = true
						return
					}
					let scrollTop = null
					const slotToScroll = this.getSlotToScroll()
					const isContainToday = !!dateRange(this.selectedDate, nextDate(this.selectedDate, this.daysCount - 1))
						.find(date => isEqualDate(date, new Date()))
					// Scroll to the closest slot on the table
					if (slotToScroll) {
						scrollTop = this.calcScrollToSlot(slotToScroll)
					}
					// Scroll to the red line if it is visible
					if (!slotToScroll && isContainToday) {
						scrollTop = this.calcScrollToRedLine(tableElem)
					} 
					if (!scrollTop){
						this.currentTimeIsSet = true
						return
					}

					tableElem.scrollTo({top: scrollTop, behavior: 'smooth'})
				}
				action()
			}
		},
		setScrollWithoutTransition(){
			let savedTableScroll = sessionStorage.getItem(`schedule-table-${this.calendarUid}-scroll`)
			sessionStorage.removeItem(`schedule-table-${this.calendarUid}-scroll`)
			if (!savedTableScroll)
				return
			let scrollTop = Number(savedTableScroll)
			let activeTable = this.activeEditableTable()
			if (!activeTable)
				return

			activeTable.scrollTop = scrollTop
			this.currentTimeIsSet = true
		},
		getSlotToScroll(){
			const from = new Date(this.selectedDate)
			const fromIsPast = dateIsPast(from)
			
			const to = new Date(this.selectedDate)
			to.setDate(to.getDate() + this.daysCount - 1)
			const toIsPast = dateIsPast(to) && !isEqualDate(to, new Date())

			const opt = {sortByTime: true}
			const currentTableSlots = this.daysCount == 1 ?
					this.slotCollection.getByDate(from, opt) :
					this.slotCollection.getByRange(from, to, opt)
					
			const slotsTimes = currentTableSlots.map(slot => {
				const startDate = slot.period.start.date
				const id = slot.index
				const minutes = startDate.getHours() * 60 + startDate.getMinutes()
				return { id, minutes }
			}).sort((a, b) => a.minutes - b.minutes)

			// If selected date range is fully past or is fully future 
			if (fromIsPast && toIsPast || !fromIsPast && !toIsPast){
				return slotsTimes.length ?
					currentTableSlots.find(slot => slot.index == slotsTimes[0].id) :
					undefined
			}
			
			// Selected date range contain today
			const today = new Date()
			const todayNotPastSlot = currentTableSlots.find(slot => {
				const startDate = slot.period.start.date
				return isEqualDate(startDate, today) && !dateIsPast(startDate)
			})
			return todayNotPastSlot	
		},

		calcScrollToSlot(slot){
			const table = this.activeEditableTable()
			const tableScroll = table.scrollTop
			if (!table)
				return 0
			const tableHeight = table.clientHeight

			let targetPosY = this.getSlotPosition(slot) + this.getSlotHeight(slot) / 2 
			if (targetPosY == 0)
				return 0
			

			const staticMenu = document.querySelector('#static__menu')
			const staticMenuHeight = staticMenu ? staticMenu.clientHeight : 0

			// Part of the table hidden by the static menu
			targetPosY -= (tableHeight - staticMenuHeight) / 2
			if (Math.abs(targetPosY - tableScroll) < 10)
				return 0
			
			return targetPosY
		},

		setPageActivity(activity){
			this.$store.dispatch('set-page-activity', activity)
		},

		submitSlot(slotData){
			this.setPageActivity(null)

			if (!slotData)
				return

			const {hour, minute, date} = slotData

			if (!this.isEditable)
				return

			const slotStartDate = new Date(date)
			slotStartDate.setHours(hour, minute, 0, 0)
			if (this.calendar.IsCommercial && dateIsPast(slotStartDate))
				return

			// Create temp slot for the UI
			const userId = this.$store.getters.userId
			const newSlot = new Slot(slotStartDate, 60, {
				id : this.addedSlots * -1,
				isQuestionSlot: true,
				hostId: userId,
			})
			const browserTZ = this.$store.getters.browserTimezone
			const calendarTZ = this.$store.getters.calendarTimezone
			const isProCalendar = this.calendar.IsCommercial

			this.slotCollection.addQuestionSlot(newSlot)
			this.slotCollection.submitSlot(newSlot, browserTZ, calendarTZ, isProCalendar)
			.then((slots) => {
				if (!slots)
					return
				const addedSlot = slots[0]
				const neighbors = this.slotCollection.getNeighbors(addedSlot)
				if (!neighbors)
					return
				// If new slot has neighbors, update them
				if (neighbors["top"])
					eventBroker.$emit(`slot-card-${neighbors["top"]}`, 'update-neighbors')
				if (neighbors["bot"])
					eventBroker.$emit(`slot-card-${neighbors["bot"]}`, 'update-neighbors')
			})
			this.addedSlots += 1
		},

		activeEditableTable(){

			let activeCarouselSlide = this.$el.querySelector('.carousel-slide.is-active')
			if (!activeCarouselSlide)
				return null
			let activeCalendarTable = activeCarouselSlide.querySelector('.calendar-v2')
			if (!activeCalendarTable)
				return null
			return activeCalendarTable
		},

		calcScrollToRedLine(table){
			const tableRedLine = table.querySelector('.red__line')
			if (!tableRedLine)
				return 0
			const tableRedLineTop = tableRedLine.style["top"].replace('px', '')
			if (Number(tableRedLineTop) == NaN)
				return 0

			const staticMenu = document.querySelector('#static__menu')
			const staticMenuHeight = staticMenu ? staticMenu.clientHeight : 0

			return Number(tableRedLineTop) - (table.clientHeight) / 2 + staticMenuHeight
		},

		getSlotHeight(slot) {
			return Math.floor(this.editTableCellHeight * slot.period.duration / 60)
		},

		getSlotPosition(slot) {
			let {hour, minute} = this.workTime.startTime()
			let workStartAlign = this.topPositionByTime(hour, minute)
			let slotStart = {
				hour: slot.period.start.date.getHours(),
				minute: slot.period.start.date.getMinutes(),
			}
			let relativeTop = this.topPositionByTime(slotStart.hour, slotStart.minute) 
			return relativeTop - workStartAlign
		},

		topPositionByTime(hour, minute){
			let topPosition = 0
			topPosition += hour * this.editTableCellHeight
			topPosition += minute * this.editTableCellHeight / 60
			return Math.floor(topPosition)
		},

		inputDateHandler(newValue){
			this.isSwipeActive = true
			if (newValue == this.dates.length - 1) {
				this.pushDateRight()
			} else if (newValue == 0 ) {
				newValue = 1
				setTimeout(() => {
					this.pushDateLeft()
					this.withoutTransition(() => {
						this.currentDateIndex = newValue
					})
					this.$emit('on-select-date', this.dates[newValue], saveCalendar)
					setTimeout(() => this.isSwipeActive = false, 50)
				}, 50);
				return
			}
			let saveCalendar = false
			this.$emit('on-select-date', this.dates[newValue], saveCalendar)
			setTimeout(() => this.isSwipeActive = false, 50)
		},
		withoutTransition(action) {
			return new Promise((resolve, _) => {
				let scheduleTable = document.getElementById('schedule-table-carousel');
				let scheduleTableSlides = scheduleTable.getElementsByClassName('carousel-slides')[0];

				scheduleTableSlides.style.transform = `translateX(-${this.currentDateIndex*100*cssVw}px)`
				scheduleTableSlides.style.transition = 'none';
				if (action)
					action()
				setTimeout(() => {
					scheduleTableSlides.style.transition = null;
					resolve()
				}, 0);
			})
		},
		pushDateRight() {
			let date = new Date(this.dates[this.dates.length - 1]);
			date.setDate(date.getDate() + 1);
			this.dates.push(date);
		},
		pushDateLeft() {
			let dates = [...this.dates]
			let date = new Date(this.dates[0]);
			date.setDate(date.getDate() - 1);
			dates.unshift(date);
			this.dates = dates;
		},
		setupDates(date){

			if (this.daysCount != 1) {
				this.dates = [date]
				return
			}

			let dates = []
			
			let prevDate = new Date(date)
			prevDate.setDate(prevDate.getDate() - 1)
			dates.push(prevDate)

			dates.push(date)

			let nextDate = new Date(date)
			nextDate.setDate(nextDate.getDate() + 1)
			dates.push(nextDate)
			this.dates = dates
		},
		syncTableTime(){
			if (!this.isEditable)
				return
			let allCarouselSlides = Array.from(this.$el.querySelectorAll('.carousel-slide'))
			let activeCarouselSlide = allCarouselSlides.find(slide => {
				return slide.classList.contains('is-active')
			})
			if (!activeCarouselSlide)
				return
			
			let anotherCarouselSlides = allCarouselSlides.filter(slide => !slide.classList.contains('is-active'))
			let activeCalendarTable = activeCarouselSlide.querySelector('.calendar-v2')
			if (!activeCalendarTable)
				return
			anotherCarouselSlides
				.map(slide => slide.querySelector('.calendar-v2'))
				.forEach(table => {
					if (table.scrollTop == activeCalendarTable.scrollTop)
						return
					table.scrollTop = activeCalendarTable.scrollTop
					table.classList.add('no-scroll')
				})
		},
		makeCalendarScrollable(){
			Array.from(this.$el.querySelectorAll('.carousel-slide'))
				.map(slide => slide.querySelector('.calendar-v2'))
				.forEach(table => table.classList.remove('no-scroll'))	
		},
		checkIntersectionWithBasket({posY}){
			const newSlotPosY = posY
			const deleteBasket = this.$el.querySelector('.delete-slot')
			if (!deleteBasket)
				return
			const deleteBasketRect = deleteBasket.getBoundingClientRect()
			const containerRect = this.$el.getBoundingClientRect()
			const deleteBasketPosY = deleteBasketRect.top - containerRect.top
			const difference = Math.abs(newSlotPosY - deleteBasketPosY)
			this.isDeletePreparation = difference < this.editTableCellHeight * 0.9
		},
	},
}
</script>

<style>

#schedule-table {
	position: relative;
	height: 100%;
	width: 100%;
}

#schedule-table-carousel {
	height: 100%;
	max-height: 100%;
}


.calendar-schedule {
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.table-varnish {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}
</style>