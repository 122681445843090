function topPositionByTime(hour, minute, cellHeight){
	let topPosition = hour * cellHeight + minute * cellHeight / 60
	return Math.floor(topPosition)
}

/**
 * Calculate the top position of the slot by the time
 * @param {Date} slotStartAt - slot start time
 * @param {CalendarWorkTime} workTime - work time bounds
 * @param {number} cellHeight - height of the hour cell in px
 * @returns {number} - top position in px
 */
function calcSlotCardPosY(slotStartAt, workTime, cellHeight){
	const {hour, minute} = workTime.startTime()
	const slotHour = slotStartAt.getHours()
	const slotMinute = slotStartAt.getMinutes()
	
	const workStartAlign = topPositionByTime(hour, minute, cellHeight)
	const relativeTop = topPositionByTime(slotHour, slotMinute, cellHeight) 
	
	return relativeTop - workStartAlign
}

export {
	calcSlotCardPosY,
} 