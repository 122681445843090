<template>
    <div class="calendar_hours_ruler">
        <div class="hour__cont"
			v-for="hour in workHours"
			:key="`${workTime.start.toLocaleDateString()}-hour-${hour}`"
			v-bind:style="{
				height: cellHeight + 'px',
			}"
		>
            <!-- <div class="line big"></div> -->
            <div class="hour">{{hour}}:00</div>
            <!-- <div class="line small"></div> -->
        </div>
        <div class="red__line"
        	ref="red-line"
        	v-show="redLineVisibility == 0">
        </div>
    </div>
</template>

<script>
import CalendarWorkTime from '../../scripts/calendarWorkTime.js';
import { isEqualDate } from '../../scripts/date.js';

export default {
    name: "Hours",

	props: {
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		cellHeight: {
			type: Number,
			default: 54,
		}
	},

    data() {
        return {
            position: 0,
            isScrolled: false,
			redLinePositionY: 0,
			interval: null,
        }
    },

    computed: {

        workHours(){
            return this.workTime.range()
        },
        
        redLineVisibility(){

			if (!this.workTime || this.workHours.length == 0)
				return -1

            const date = new Date()
			const dateMinutes = date.getHours() * 60 + date.getMinutes()
			const startMinutes = this.workTime.start.getHours() * 60 + this.workTime.start.getMinutes()
			const endMinutes = isEqualDate(this.workTime.start, this.workTime.end) ? 
				this.workTime.end.getHours() * 60 + this.workTime.end.getMinutes() :
				1440 // 24 hours
            
			const isGreaterEnd = dateMinutes > endMinutes
			if (isGreaterEnd)
				return 1
			const isLessStart = dateMinutes < startMinutes
			if (isLessStart)
				return -1
            return 0
        }
    },

	watch: {
		workTime(newWorkTime){
			if (newWorkTime) {
				this.redLinePositionY = this.getPosition(new Date())
				this.setRedLinePosY()
			}
		}
	},

    created() {
		console.log('set interval :>> ');
		const currentDate = new Date()
		this.redLinePositionY = this.getPosition(currentDate)
		this.setRedLinePosY()
        this.interval = setInterval(() => this.intervalFunc(currentDate), 60000)
    },

	beforeDestroy() {
		clearInterval(this.interval)
	},

    methods:{
		intervalFunc(currentDate){
			console.log('set red line :>> ');
			currentDate.setMinutes(currentDate.getMinutes() + 1)
			this.redLinePositionY = this.getPosition(currentDate)
			this.setRedLinePosY()
		},
		getPosition(date){
			if (this.workHours.length == 0)
				return null
			const visibility = this.redLineVisibility
			if (visibility == -1) {
				return 0
			} else if (visibility == 1) {
				return this.workHours.length * this.cellHeight
			}

			let topAlign = this.workHours[0]
            let position = date.getHours() + ( date.getMinutes() / 60 ) - topAlign
            return position * this.cellHeight
        },
		setRedLinePosY(){
			const redLineElem = this.$refs['red-line']
			if (!redLineElem)
				return
			redLineElem.style.top = this.redLinePositionY + 'px'
		},
        // updateScrollPosition(){
        //     if (!this.redLineIsVisible || !this.redLinePositionY || this.isScrolled)
        //         return
        //     let redLine = this.$refs['red-line']
        
        //     let redlineTablePosition = this.redLinePositionY
        //     let redLineWindowPosition = redLine.getBoundingClientRect().top 

        //     let tableVisibleHeight = 100 * cssVh - 32 - 72 - 51 - 48 - 50

        //     let screenStart = window.scrollY
        //     let screenEnd = screenStart + cssVh * 100
        //     let calendarPage = document.getElementById('layout__content')

        //     // if the red line is below the screen
        //     if (redLineWindowPosition > screenEnd ){
        //         let futurePosition = calendarPage.scrollTop + tableVisibleHeight / 2
        //              + (redlineTablePosition - tableVisibleHeight)
        //         this.scrollContent(futurePosition)          
        //     }
            
        //     // if the red line is above the screen
        //     if (redLineWindowPosition < screenStart){
        //         let futurePosition = calendarPage.scrollTop - tableVisibleHeight / 2 
        //             + (redlineTablePosition - tableVisibleHeight)
        //         this.scrollContent(futurePosition)
        //     }
        // },
        // scrollContent(futurePosition){
        //     let calendarPage = document.getElementById('layout__content')

        //     calendarPage.scrollTo({
        //         top: futurePosition,
        //         behavior: 'smooth'
        //     })
        //     this.isScrolled = true   
        // }
    }
}
</script>

<style scoped>

.calendar_hours_ruler{
    position: relative;
}
hr{
    margin: 0;
}
.hour__cont{
	width:30px;
    position: relative;
}
.hour{
    position: absolute;
    font-size:10px;
    top: -7px;
    right: 4px;
    color: rgb(143, 143, 143);
    z-index: 2;
}

.big{
    width: 15px;
    float: right;
}
.small{
    width: 8px;
    float: right;
}
.line{
    border-bottom: 1px solid skyblue;
    margin-right: 4px;
}
.small{
    position:absolute;
    top : 50%;
    right: 0;
}
.red__line{
    position:absolute;
    float:right;
    background: red;
	width: 20px;
	height: 2px;
}

.red__line hr {
    background: red;
}
.hour__cont:first-child{
    height: 54px;
}
</style>