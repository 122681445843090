<template>
	<div class="slot-card-separator" v-bind:style="{
		background: color,
	}">
	</div>
</template>

<script>
export default {
	name: 'SlotCardSeparator',
	props: {
		color: {
			type: String,
			default: 'black',
		}
	},
	data() {
		return {
		
		};
	},

	mounted() {
		
	},

	methods: {
		
	},
};
</script>

<style scoped>
.slot-card-separator{
	position: absolute;
	z-index: 3;
	bottom: -1px;
	left: 20px;
	right: 20px;
	height: 2px;
	border-radius: 2px;
	background: black;
}
</style>